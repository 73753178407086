"use client"

import { ABI } from '@/app/tournament-abi';
import { Tournament } from '@/lib/schema';
import { formatNumber, groupTournamentsByGameId } from '@/lib/utils';
import { useUser } from '@clerk/nextjs';
import { sendGAEvent } from '@next/third-parties/google';
import { Loader } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { useAccount, useChainId, useReadContract, useSimulateContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { useCapabilities, useWriteContracts } from 'wagmi/experimental';
import { Dropdown } from '../Dropdown';
import { Button } from '../ui/button';
import { DialogFooter } from '../ui/dialog';
import { Select, SelectGroup, SelectItem, SelectLabel } from '../ui/select';

function ClaimTokens({ claimableTournaments, selectedTournament }: { claimableTournaments: Tournament[], selectedTournament: Tournament }) {
    const { address, connector } = useAccount()
    const chainId = useChainId()
    const { user } = useUser()
    const [shouldShowFireworks, setShouldShowFireworks] = useState(false)

    const groupedTournaments = groupTournamentsByGameId(claimableTournaments)
    const [dropdownSelectedTournament, setDropdownSelectedTournament] = useState<Tournament | null>(selectedTournament)

    const handleTournamentChange = (tournamentId: string) => {
        const newSelectedTournament = claimableTournaments.find(t => t.tournamentId === parseInt(tournamentId));
        if (newSelectedTournament) {
            setDropdownSelectedTournament(newSelectedTournament);
        }
    };

    const { data: resultData, isFetching } = useReadContract({
        abi: ABI,
        address: dropdownSelectedTournament?.address as `0x${string}`,
        functionName: 'claimablePrizesForTournament',
        query: {
            enabled: Boolean(dropdownSelectedTournament && address),
        },
        args: [address as `0x${string}`, BigInt(dropdownSelectedTournament?.tournamentId as number)],
    })

    const claimablePrize = BigInt(resultData as unknown as string || 0)

    const { data: claimSimulate } = useSimulateContract({
        address: dropdownSelectedTournament?.address as `0x${string}`,
        abi: ABI,
        functionName: 'claim',
        args: [BigInt(dropdownSelectedTournament?.tournamentId as number)],
        query: {
            enabled: Boolean(dropdownSelectedTournament && address && claimablePrize > BigInt(0))
        }
    })

    const { writeContracts: writeClaimTokens } = useWriteContracts({
        mutation: {
            onSuccess: async () => {
                setShouldShowFireworks(true)
                sendGAEvent('event', 'claim_tokens', { wallet: address, email: user?.primaryEmailAddress?.emailAddress, username: user?.username })
                setTimeout(() => {
                    setShouldShowFireworks(false)
                }, 8000)
            },
        }
    });
    const { data: availableCapabilities } = useCapabilities({
        account: address,
        query: {
            enabled: Boolean(connector?.name === 'Coinbase Wallet')
        }
    });

    const capabilities = useMemo(() => {
        if (!availableCapabilities || !chainId) return {};
        const capabilitiesForChain = availableCapabilities[chainId];

        if (
            capabilitiesForChain["paymasterService"] &&
            capabilitiesForChain["paymasterService"].supported
        ) {
            return {
                paymasterService: {
                    url: `${process.env.NEXT_PUBLIC_BASE_SITE_URL}/api/paymaster`,
                },
            };
        }
        return {};
    }, [availableCapabilities, chainId]);
    const { writeContract: claimFromContract, data: writeData } = useWriteContract()
    const { isSuccess } = useWaitForTransactionReceipt({ hash: writeData })

    useEffect(() => {
        if (!address) return
        if (isSuccess) {
            sendGAEvent('event', 'claim_tokens', { wallet: address, email: user?.primaryEmailAddress?.emailAddress, username: user?.username })
            setShouldShowFireworks(true)
        }
        return () => {
            setShouldShowFireworks(false)
        }
    }, [isSuccess, address])

    if (isFetching) return <Loader className='animate-spin w-6 h-6 min-h-6' />

    return (
        <div className="grid gap-6 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
                <Dropdown name={dropdownSelectedTournament ? `${dropdownSelectedTournament.gameName} - ${dropdownSelectedTournament?.tournamentId}` : 'Select Tournament'}>
                    <Select onValueChange={handleTournamentChange}>
                        {Object.entries(groupedTournaments).map(([category, tournaments]) => (
                            <SelectGroup key={category}>
                                <SelectLabel>
                                    <p className='text-purple-800 text-xl'>{category}</p>
                                </SelectLabel>
                                {Object.entries(tournaments).map(([gameId, tournaments]) => (
                                    <SelectItem className='cursor-pointer' key={gameId} value={tournaments[0].tournamentId.toString()}>
                                        Tournament: {tournaments[0].tournamentId} {`(${tournaments[0].gameName})`}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        ))}
                    </Select>
                </Dropdown>
            </div>

            <div className='flex justify-between border-t pt-4'>
                <p className="py-2">
                    Prize: {claimablePrize ? formatNumber(claimablePrize.toString(), dropdownSelectedTournament?.paymentTokenDecimals || 18) : 0} {dropdownSelectedTournament?.paymentTokenSymbol}
                </p>
            </div>

            <DialogFooter className="pt-4">
                {shouldShowFireworks ? <Confetti
                    width={400}
                    height={400}
                /> : null}
                <Button
                    disabled={claimablePrize === 0n || !claimSimulate}
                    onClick={() => {
                        if (!claimSimulate) return
                        if (connector?.name != 'Coinbase Wallet') {
                            console.log(`Falling back to legacy wallet claim - `, claimSimulate)
                            claimFromContract(claimSimulate.request)
                        } else {
                            writeClaimTokens({
                                contracts: [
                                    {
                                        abi: ABI,
                                        address: dropdownSelectedTournament?.address as `0x${string}`,
                                        functionName: 'claim',
                                        args: [BigInt(dropdownSelectedTournament?.tournamentId as number)],
                                    },
                                ],
                                capabilities,
                            });
                        }
                    }}
                    className="text-white font-bold py-2 px-4 w-1/3"
                >
                    Claim
                </Button>
            </DialogFooter>
        </div>

    )
}

export default ClaimTokens