"use client";

import { PendingLink, WalletObject } from "@/lib/schema";
import { truncateWalletAddress } from "@/lib/utils";
import { handleTelegramConfirm } from "@/server-actions/telegram-confirm";
import { useUser } from "@clerk/nextjs";
import { sendGAEvent } from "@next/third-parties/google";
import { CheckCircleIcon, MessageCircleWarningIcon } from "lucide-react";
import { Button } from "./ui/button";


export const TelegramLink = ({ linkInvite, walletObject }: { linkInvite: PendingLink | null, walletObject: WalletObject | null }) => {
    const { user } = useUser();

    const handleLinkTelegram = () => {
        if (!user?.unsafeMetadata?.wallet) {
            return
        }
        sendGAEvent('event', 'link_telegram', { wallet: user?.unsafeMetadata?.wallet, email: user?.primaryEmailAddress?.emailAddress || "" })
        window.open(`https://t.me/metacade_tournaments_bot?start=${user?.unsafeMetadata?.wallet}`, '_blank');
    }

    const handleConfirmTelegram = async () => {
        if (!linkInvite) return;
        if (user) {
            sendGAEvent('event', 'link_telegram_confirm', { wallet: user?.unsafeMetadata?.wallet, email: user?.primaryEmailAddress?.emailAddress || "" })
            await handleTelegramConfirm({ action: 'confirm' })
            await user.reload()
        }
    }

    const handleUnlinkTelegram = async () => {
        if (user) {
            await handleTelegramConfirm({ action: 'unlink' })
            await user.reload()
        }
    }

    const isTelegramLinked = Boolean(walletObject?.telegramUserId || "");

    return (
        <div className="telegram-link space-y-4">
            <h1 className="font-bold text-xl">Link Telegram</h1>
            <p>In order to use the Metacade Tournaments Platform via Telegram, you will need to link it to your account.</p>
            <p>Linking your Telegram to your account allows you to play Metacade games via Telegram and earn valuble credits that can be used to enter tournaments.</p>
            <div className="flex space-x-2">
            </div>
            <div className="flex items-center space-x-2">
                {isTelegramLinked && (
                    <>
                        <p>Your account is currently linked to telegram user ID {String(walletObject?.telegramUserId)}</p>
                        <CheckCircleIcon height={25} width={25} className="text-green-400" />
                    </>
                )}
                {linkInvite?.status === 'PENDING' && (
                    <div className=" space-y-2">
                        <div className="flex">
                            <p>Your account is currently pending a telegram link</p>
                            <MessageCircleWarningIcon height={25} width={25} className="text-yellow-400 ml-2" />
                        </div>
                        <p className="text-muted-foreground text-xs">Confirm the link for telegram ID {linkInvite.telegramUserId} and wallet {truncateWalletAddress(linkInvite.wallet)}</p>

                        <div className="flex space-x-2">
                            <Button onClick={handleConfirmTelegram}>
                                Confirm Link
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {linkInvite?.status != 'PENDING' && <div className="flex space-x-2">
                <Button onClick={handleLinkTelegram} disabled={isTelegramLinked}>
                    Link Telegram
                </Button>
                <Button onClick={handleUnlinkTelegram} disabled={!isTelegramLinked}>
                    Unlink Telegram
                </Button>
            </div>}
        </div>
    );
};

