import { Button } from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "@/components/ui/dialog"

export function Modal({ children, heading, description, disabled, setOpen, isOpen, className }: {
    children: React.ReactNode,
    heading: string,
    description: string,
    disabled: boolean,
    setOpen: (open: boolean) => void,
    isOpen: boolean,
    className?: string
}) {
    return (
        <Dialog onOpenChange={setOpen} open={isOpen}>
            <DialogTrigger asChild>
                <Button
                    disabled={disabled}
                    variant="outline"
                    className={`relative hud-btn w-4/6 border-pink-600 shadow-inner hover:bg-pink-600 text-pink-600 text-bold px-4 py-2 flex items-center space-x-2 my-2 transition-transform duration-300 hover:scale-110 ${className || ''}`}
                >
                    {heading}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{heading}</DialogTitle>
                    <DialogDescription>
                        {description}
                    </DialogDescription>
                </DialogHeader>
                {children}
            </DialogContent>
        </Dialog>
    )
}

